<template>
	<div>
		
		<b-card class="mb-4 w-100 mt-3">
			<div class="venue-heading mt-2">
				<b-row>
					
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="4"
					md="4"
					sm="12"
					>
						<div class="w-100">
                            <multiselect
                            v-model="vendor_selected"
              :options="vendor_option"
              placeholder="Select Vendor Name"
              track-by="name"
              label="name"
              >
              </multiselect>
						</div>
					</b-col>
                    <b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="2"
					md="4"
					sm="12"
					>
						<div class="w-100">
							<b-form-datepicker
							id="example-datepicker"
							v-model="start_date"
							placeholder="Start date"
							class="mb-2"
							:max="end_date"
							:date-format-options="{
							year: 'numeric',
							month: 'short',
							day: '2-digit',
							}"
							></b-form-datepicker>
						</div>
					</b-col>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="2"
					md="4"
					sm="12"
					>
						<div class="w-100">
							<b-form-datepicker
							id="example-datepicker2"
							placeholder="End date"
							:min="start_date"
							v-model="end_date"
							class="mb-2"
							:date-format-options="{
							year: 'numeric',
							month: 'short',
							day: '2-digit',
							}"
							></b-form-datepicker>
						</div>
					</b-col> 
					
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="4"
					md="12"
					sm="12"
					>
						<div class=" d-flex justify-content-end button-text">
							<div class="p-1">
								<b-button variant="primary" @click="applyFilter()">
									Apply</b-button
								>
							</div>
							<div class="p-1">
								<b-button variant="primary" @click="resetFilter()">
									Reset</b-button
								>
							</div>
							<!-- <div class="p-1">
								<b-button id="button_width" variant="primary" @click="AddOffline">
									<i class="mdi mdi-plus"></i> Add Booking</b-button
								>
								
							</div>
							
							<div class="p-1">
								<b-button
								id="button_width"
								@click="export_pdf()"
								variant="primary"
								>
									<i class="mdi mdi-export"></i>Export</b-button
								>
							</div> -->
						</div>
					</b-col>
				</b-row>
			</div>
			
			<div>
				<b-row>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="7"
					md="12"
					sm="12"
					>
						<div
						class="
						w-100
						justify-content-sm-center
						justify-content-xl-start
						justify-content-md-start
						"
						>
							<div class="mt-2 venue-flex">
								<b-form-select
								style="max-width: 90px;"
								id="select_perpage"
								v-model="pageSize"
								:options="options"
								@change="handlePageSizeChange($event)"
								></b-form-select>
								
							</div>
						</div>
					</b-col>
					
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="5"
					md="12"
					sm="12"
					>
						<div class="w-100 p-1">
							<b-form-input
							v-model="search"
							id="search"
							type="text"
							placeholder="Search here"
							v-on:keyup="key_search()"
							></b-form-input>
						</div>
					</b-col>
				</b-row>
			</div>
			<br />
			<div>
				<b-table
				:busy="load"
				:fields="fields"
				:items="bookings"
				responsive
				hover
				bordered
				show-empty
				empty-text="No Data Available"
				class="text-center"
				
				>
					<template  #cell(total_booking_price)="data">
						<span v-html="currency_check(data.item.total_booking_price)"></span>
					</template>
                    <template  #cell(tax_amount)="data">
						<span v-html="currency_check(data.item.tax_amount)"></span>
					</template>
                    <template  #cell(total_amount)="data">
						<span v-html="currency_check(data.item.total_amount)"></span>
					</template>
					<template  #cell(vb_per)="data">
						<span v-html="currency_check(data.item.vb_per)"></span>
					</template>
					<template  #cell(deducted_tds)="data">
						<span v-html="currency_check(data.item.deducted_tds)"></span>
					</template>
					
					<template #cell(booking_id)="data">

						
						<!-- <i class="fas fa-times fa-lg text-danger cursor-pointer" v-if="data.item.status=='Processing'" @click="RejectPayment(data.item.total_amount,data.item.booking_id)" ></i>  &nbsp;  -->
						<i class="fas fa-check fa-lg text-success cursor-pointer" v-if="data.item.status=='Processing'" @click="addPayment(data.item.total_amount,data.item.booking_id)"></i> 
						
						<router-link :to="'/vendor_invoice/invoice_list/' + data.item.select_month +'/'+data.item.to_month+'/'+data.item.vendor_id">
							<i class="fas fa-eye fa-lg text-dark ml-2"></i>
						</router-link> 
					
						<router-link :to="'/Finance/vb/invoice/' + data.item.booking_id" >
							<i class="fas fa-file fa-lg text-dark ml-2"></i>
						</router-link>
&nbsp;
					<!-- 	<i class="fas fa-check fa-lg text-danger cursor-pointer "  v-if="data.item.status!='Paid'" @click="addPayment(data.item.vb_per_wihout_coma,data.item.Date1)"></i>
            <i class="fas fa-check fa-lg text-success " v-else></i>  -->
                  
					</template>

					
				</b-table>
				<b-row align-v="center">
					<b-col cols="4">
						<span
						>Showing <b>{{ totalRows }}</b> out of
							<b>{{ totalData }}</b> entries.</span
						>
					</b-col>
					<b-col cols="8">
						<b-pagination
						v-model="page"
						:total-rows="count"
						:per-page="pageSize"
						@change="handlePageChange"
						prev-text="Prev"
						next-text="Next"
						align="right"
						></b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
	import axios from "axios";
    import Multiselect from "vue-multiselect"; 
    import { mapState } from 'vuex'
	export default {
		name: "mybooking",
		data: () => ({
			title: "mybooking",
			
			fields: [
			//  'index',
			{
				key: "booking_timeline",
				label: "Booking Timeline",
				sortable: true,
				tdClass: 'text-left',
				thClass: 'text-left',
			},
			{
				key: "Date",
				label: "Invoice Date",
				sortable: true,
				tdClass: 'text-left',
				thClass: 'text-left',
			},
			
		
			/*  {
				key: "billing_phone",
				label: "Billing Phone",
				},
				{
				key: "billing_email",
				label: "Billing Email",
				sortable: true,
			},*/
			{
				key: "auto_increment",
				label: "Invoice No.",
				sortable: true,
				tdClass: 'text-left',
				thClass: 'text-left',
			},
			
			{
				key: "total_booking_price",
				label: "Full Amount",
				sortable: true,
				tdClass: 'text-right',
			},
			{
				key: "deducted_tds",
				label: "TDS Deducted",
				sortable: true,
                tdClass: 'text-right',
			},
            {
				key: "total_amount",
				label: "Payable Amount",
				sortable: true,
                tdClass: 'text-right',
			},
			/* {
				key: "vb_per",
				label: "VB Amount",
				sortable: true,
                tdClass: 'text-right',
			},  */
			{
				key: "status",
				label: "Status",
				sortable: true,
				tdClass: 'text-left',
				thClass: 'text-left',
			}, 
			/*  {
				key: "from_date",
				label: "From Date",
				sortable: true,
				},
				{
				key: "to_date",
				label: "To Date",
				sortable: true,
			}, */
			{
				key: "booking_id",
				label: "Action",
			},
			],
			bookings: [],
			options: [10, 20, 30, 50],
			
			pageSize: 10,
			show: false,
			load: true,
			filter: "",
			search: "",
			CurrentPage: 1,
			sortBy: "created_at",
			
			page: 1,
			count: 0,
			totalData: "",
			totalRows: "",
			
			PerPage: "50",
			venue_names: [],
			start_date: "",
			end_date: "",
			booked_on: "",
			venue_name_value: "",
			gst_check: false,
			tan_check: false,
			curncy: "",
            vendor_option: [],
            vendor_selected: '',
		}),
		components: { Multiselect },
		methods: {
			key_search() {
				const value = "1";
				this.handlePageChange(value);
			},
			handlePageChange(value) {
				this.page = value;
				this.load = true;
				this.bookingListing();
			},
			applyFilter() {
				const value = "1";
				this.handlePageChange(value);
			},
			getRequestParams(page, pageSize, sortBy = "created_at") {
				let params = {};
				if (page) {
					params["page"] = page - 1;
				}
				if (pageSize) {
					params["size"] = pageSize;
				}
				params["sortBy"] = sortBy;
				return params;
			},
			bookingListing() {
				//SET_BOOKING_DETAILS
				this.$store.commit("SET_BOOKING_DETAILS", 'vbbook');
				this.$store.commit("SET_BOOKING_HEADER", 'Invoice');
                this.$store.commit("SET_ADMIN_ID", this.vendor_selected);
				this.load = true;
				// this.venue = [];
				var keyword = this.search;
				const params = this.getRequestParams(
				this.page,
				this.pageSize,
				this.sortBy
				);
				axios
				.post("/admin/Admin_invoice", {
					params,
					search_keyword: keyword,
					from_date: this.start_date,
					to_date: this.end_date,
					booked_on: this.booked_on,
					venue_name: this.vendor_selected,
					type:"book",
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						this.bookings = [];
						if (resp.data.status) {
							
							//var tax_amount;
						
							//var payable;
							//var deducted_tds;
							//var gst_check;
							for (var i = 0; i < resp.data.bookings.length; i++) {
                               
								//this.bookings.push({
									/* Date: this.date_format(resp.data.bookings[i].first_date),
									Date1: resp.data.bookings[i].first_date,
									booking_id: resp.data.bookings[i].booking_id,
									auto_increment: resp.data.bookings[i].auto_increment,
									select_month: resp.data.bookings[i].select_month,
									booking_auto_id: resp.data.bookings[i].booking_auto_id,
									user_id: resp.data.bookings[i].user_id,
									status:resp.data.bookings[i].status ==0 ? 'Not Paid':'Paid',
								//    balance_amount:
								//	this.formatPrice(resp.data.bookings[i].total_invoice),

									total_booking_price: this.formatPrice(Math.round(payable)),
									deducted_tds:Math.round(deducted_tds),
									total_amount: this.formatPrice(Math.round(parseFloat(payable)-parseFloat(deducted_tds))),
									vb_per_wihout_coma: Math.round(parseFloat(payable)-parseFloat(deducted_tds)), */

									/* Date: this.date_format(resp.data.bookings[i].vb_month),
									Date1: resp.data.bookings[i].vb_month,
									booking_id: resp.data.bookings[i].id,
									vendor_id: resp.data.bookings[i].vendor_id,
									auto_increment: resp.data.bookings[i].invoice_id,
									select_month: resp.data.bookings[i].from_month,
									to_month: resp.data.bookings[i].to_month,
									booking_auto_id: resp.data.bookings[i].booking_auto_id,
									booking_timeline: this.date_format(resp.data.bookings[i].inv_from) +' - '+  this.date_format(resp.data.bookings[i].inv_to),
									//status:resp.data.bookings[i].payment_status ==0 ? 'Not Paid':'Paid',
								
									total_booking_price: this.formatPrice(Math.round(payable)),
									deducted_tds:Math.round(deducted_tds),
									total_amount: this.formatPrice(Math.round(resp.data.bookings[i].vb_amount)),
									vb_per_wihout_coma: Math.round(parseFloat(payable)-parseFloat(deducted_tds)),
									status:resp.data.bookings[i].payment_status ==0 ? 'Not Paid': resp.data.bookings[i].payment_status ==1 ? 'Processing' : resp.data.bookings[i].payment_status == 2 ? 'Paid' :'Rejected',
									
								}); */


								this.bookings.push({
									Date: this.date_format(resp.data.bookings[i].vb_month),
									Date1: resp.data.bookings[i].vb_month,
									vendor_id: resp.data.bookings[i].vendor_id,
									booking_id: resp.data.bookings[i].id,
									auto_increment: resp.data.bookings[i].invoice_id,
									select_month: resp.data.bookings[i].from_month,
									to_month: resp.data.bookings[i].to_month,
									booking_auto_id: resp.data.bookings[i].booking_auto_id,
									status:resp.data.bookings[i].payment_status ==0 ? 'Not Paid': resp.data.bookings[i].payment_status ==1 ? 'Processing' : resp.data.bookings[i].payment_status == 2 ? 'Paid' :'Rejected',
								//    balance_amount:
								//	this.formatPrice(resp.data.bookings[i].total_invoice),
								booking_timeline: this.date_format(resp.data.bookings[i].inv_from) +' - '+  this.date_format(resp.data.bookings[i].inv_to),
									
									total_booking_price: this.formatPrice(Math.round(resp.data.bookings[i].p_vb_amount)),
									deducted_tds: this.formatPrice(Math.round(resp.data.bookings[i].tds_deduct)),
									//deducted_tds:Math.round(deducted_tds),
									total_amount: this.formatPrice(Math.round(parseFloat(resp.data.bookings[i].payable))),
									vb_per_wihout_coma: 0//Math.round(parseFloat(payable)-parseFloat(deducted_tds)),
									
								});
                            
                        }
							this.show = false;
							this.load = false;
							} else {
							this.count = this.totalData = resp.data.total_records;
							this.totalRows = resp.data.bookings.length;
							
							this.show = false;
							this.load = false;
						}
					}
				});
			},
			formatPrice(value) {
				//let val = (value/1).toFixed(2).replace(',', '.')
				var num = value ? value : 0;
			return num.toLocaleString('en-IN')
			},
			handlePageSizeChange(event) {
				this.pageSize = event;
				this.load = true;
				this.page = 1;
				this.bookingListing();
			},
			AddOffline() {
				this.$router.push("/add-booking");
			},
			resetFilter() {
				this.start_date = "";
				this.end_date = "";
				this.search = "";
				this.booked_on = "";
				(this.venue_name_value = ""), this.bookingListing();
			},
			export_pdf() {
				var keyword = this.search;
				const params = this.getRequestParams(
				this.page,
				this.pageSize,
				this.sortBy
				);
				axios
				.post(
				"/admin/bookings_pdf_export",
				{
					params,
					search_keyword: keyword,
					from_date: this.start_date,
					to_date: this.end_date,
					booked_on: this.booked_on,
					venue_name: this.venue_name_value,
				},
				{
					responseType: "blob",
				}
				)
				.then((resp) => {
					const url = window.URL.createObjectURL(new Blob([resp.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "booking_list.pdf");
					document.body.appendChild(link);
					link.click();
				});
			},
			getVenueName() {
				axios.post("/admin/get_child_venue_name").then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.venue_names = resp.data.venue_names;
						}
					}
				});
			},
			newPrint(book_id)
			{
				book_id
				var printWindow = window.open('', '', 'height=400,width=800');
				
				printWindow.document.write('<html><head><title></title><link href="/css/finsoft-print.css" rel="stylesheet"><style type="text/css"> .table{ border-collapse: collapse;width:100%;}.table-bordered>:not(caption)>*>* { border-width: 0 1px;}.table>:not(caption)>*>* {padding: 0.5rem 0.5rem;}.table-bordered>:not(caption)>* { border-width: 1px 0;}tbody, td, th, thead, tr { border-style: solid; border-width: 0;}</style></head>');
				printWindow.document.write('<body class="background-image" style="padding:10px;"><div class="page-header " >');
				printWindow.document.write('<div class="row top-headers overlays">');
				printWindow.document.write('<div class="left-side" >');
				printWindow.document.write('<div style="color:#387a6c; font-family: cursive;font-size:16px">ddd</div>');
				printWindow.document.write('<div style="color:#387a6c; font-family: cursive;font-size:14px">C.R.: dddd</div>');
				printWindow.document.write('</div>');
				
				printWindow.document.write('<div class="logo-side" >');
				printWindow.document.write('<img src="../../assets/images/venue-logo.png" class="logo-booking" alt="Booking logo">');
				printWindow.document.write('</div>');
				
				printWindow.document.write('<div class="right-side">');
				
				printWindow.document.write('<div style="color:#387a6c; font-size:18px; margin-right:10px">مؤسسة القيادة العربية</div>');
				printWindow.document.write('<div style="color:#387a6c; font-size:14px ;margin-right:10px">للتشغيل والصيانة</div>');
				printWindow.document.write('<div style="color:#387a6c; font-size:14px ; margin-right:10px">C.R .: </div>');
				printWindow.document.write('</div>');
				printWindow.document.write('</div>');
				
				
				printWindow.document.write('</div>');
				
				printWindow.document.write('<div class="page-footer">');
				printWindow.document.write('<div class="dflex d-between">');
				printWindow.document.write('<div class="w-33" >');
				printWindow.document.write('<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin-map mt-10" viewBox="0 0 16 16">');
				printWindow.document.write('<path fill-rule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z"/>');
				printWindow.document.write('<path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"/>');
				printWindow.document.write('</svg>	ddddd');
				printWindow.document.write('</div>');
				printWindow.document.write('<div class="w-33" >');
				printWindow.document.write('<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-at mt-10" viewBox="0 0 16 16">');
				printWindow.document.write('<path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/>');
				printWindow.document.write('<path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>');
				printWindow.document.write('</svg>	ddddd ');
				printWindow.document.write('</div>');
				printWindow.document.write('<div class="w-33" >');
				printWindow.document.write('<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-diagram-2 mt-10" viewBox="0 0 16 16">');
				printWindow.document.write('<path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 5 7h2.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM3 11.5A1.5 1.5 0 0 1 4.5 10h1A1.5 1.5 0 0 1 7 11.5v1A1.5 1.5 0 0 1 5.5 14h-1A1.5 1.5 0 0 1 3 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1A1.5 1.5 0 0 1 9 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"/>');
				printWindow.document.write('</svg>	Branch :');
				printWindow.document.write('</div>');
				printWindow.document.write('</div>');
				printWindow.document.write('<div class="dflex d-between">');
				printWindow.document.write('<div class="w-33" >');
				printWindow.document.write('<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">');
				printWindow.document.write('<path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>');
				printWindow.document.write('</svg> 	ddddd ');
				printWindow.document.write('</div>');
				printWindow.document.write('<div class="w-33" >');
				printWindow.document.write('<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">');
				printWindow.document.write('<path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>');
				printWindow.document.write('</svg> 	 dddd');
				printWindow.document.write('</div> ');
				printWindow.document.write('<div class="w-33" >');
				printWindow.document.write('ddddd');
				printWindow.document.write('</div> ');
				printWindow.document.write('</div>'); 
				printWindow.document.write('</div>');
				printWindow.document.write('<div class="print-content">');
				printWindow.document.write('dddd');
				printWindow.document.write('</div></body></html>');
				printWindow.document.close();
				printWindow.onload = function () { printWindow.print(); }
				window.close();
			},
			currency_check(amt) {
				
				
				//return '<i class="mdi '+this.curncy+'"></i>'+amt;
				return '<i class="mdi mdi-currency-inr"></i>'+amt;
			},
			getProfile() {
				axios.get("/admin/get_profile").then((resp) => {
					if (resp.data.status_code == 200) {
						this.curncy= 'mdi-inr';
						this.gst_check= false;
						this.tan_check= false ;
					}
				});
			},
			invoiceGenerate(booking_id)
			{
				axios.get("/admin/invoiceGenerated?booking_id="+booking_id).then((resp) => {
					resp
				});
			},
			addPayment(tax_amount,id)
			{
				this.$swal({
					title: 'Are you sure to Receive '+tax_amount+' ?',
					text: "please  confirm amount!",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, Receive'
					}).then((result) => {
					if (result.value) {
						this.save_taxamount(tax_amount,id);
					}
				}); 
			},
			save_taxamount(tax_amount,id)
			{
				axios.post("/admin/vb_admin_payable_amount",{
					amt:tax_amount,
					id:id,
					status:2
				}).then((resp) => {
					/* if (resp.data.status_code == 200) {
						this.bookingListing();
					} */
					resp;
					this.bookingListing();
				});
			},
			
			RejectPayment(tax_amount,id)
			{
				this.$swal({
					title: 'Are you sure to Reject ?',
					text: "please  confirm !",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, Reject'
					}).then((result) => {
					if (result.value) {
						this.reject_taxamount(tax_amount,id);
					}
				}); 
			},
			reject_taxamount(tax_amount,id)
			{
				axios.post("/admin/vb_admin_payable_amount",{
					amt:tax_amount,
					id:id,
					status:3
				}).then((resp) => {
					if (resp.data.status_code == 200) {
						this.bookingListing();
					}
				});
			},
			date_format:function(date)
		{
			var d1 = new Date(date);
			var ms = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			return d1.getDate() + '' + this.getDateOrdinal(d1) +' ' + ms[d1.getMonth()] + ' ' + d1.getFullYear();
		},
		getDateOrdinal(date) {
				let day = date.getDate();
				if (day > 3 && day < 21) return 'th';
				switch (day % 10) {
					case 1:  return "st";
					case 2:  return "nd";
					case 3:  return "rd";
					default: return "th";
				}
			},
            vendorListing() {
      axios.post("/admin/get_all_vendors_list").then((resp) => {
        if (resp.data.status_code == 200) {
          if (resp.data.status) {
            for (var i = 0; i < resp.data.vendors.length; i++) {
              this.vendor_option.push({
                value: resp.data.vendors[i].user_id,
                name:
                  resp.data.vendors[i].first_name +
                  " " +
                  resp.data.vendors[i].last_name,
              });
            }
          }
        }
      });
    },

		},
		mounted() {
            this.vendor_selected=this.set_admin_id;
			this.vendorListing();
			this.getProfile();
			this.bookingListing();
			this.getVenueName();
			
			document.documentElement.scrollTop = 0;
		},
        computed: {
    ...mapState(["set_admin_id"]),
}
	};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
	#search {
	max-width: 280px;
	}
</style>