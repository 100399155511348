<template>
	<div>
		<b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4>{{ set_booking_header }}</h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item active>{{ set_booking_header }}</b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
		</b-row>
		<br>
		<b-tabs   class="mt-3 finance-custom-navbar" v-model="tabIndex">
			
			<b-tab  class="mt-3" lazy :active="set_booking_option=='vbbook'">
				<template #title>
					<strong>Invoice</strong>
				</template>
				<transition name="slide">
				<admInvoice/>
				</transition>
				
			</b-tab>
			<b-tab  class="mt-3" lazy :active="set_booking_option=='inv'">
				<template #title>
					<strong>Vendor Invoice</strong>
				</template>
				<transition name="slide">
					<vendor_invoice/>
				</transition>
				
			</b-tab>
		</b-tabs>
		
	</div>
</template>

<script>

import { mapState } from "vuex";
import admInvoice from './admInvoice.vue'
import vendor_invoice from './vendor_invoice.vue'
/*import Block_invoice from './Block_invoice.vue'
import Quotation from './Quotation.vue'
import ProformaInvoice from './perfouma.vue'
import vbInvoice from './vbinvoice.vue' */
export default {
	data:() => ({
		tabIndex: 1
	}),
  components:
  {
    admInvoice,
    vendor_invoice,
    /* Invoice,
    Block_invoice,
    Quotation,
	vbInvoice,
	ProformaInvoice, */
  },
  mounted()
  {
	
  },
  computed: {
    ...mapState(['set_booking_option','set_booking_header']),
   },
   created() {
	//setTimeout(() => 
	//this.$store.commit("SET_BOOKING_DETAILS", 'perfo')
			//	, 2000);
	}
  }
</script>

<style>
	/* Fade  */
	.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
</style>